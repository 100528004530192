<template>
    <main>
        <side-bar :menuActive="sidebarData" />
        <div class="container-fluid mb-5">
            <div class="row">
                <breadcrumb :crumbs="crumbsData" />
            </div>
            <div class="row">
                <div class="col-auto col-lg-4 col-md-6 col-sm-6">
                    <div class="card mt-3 mb-4">
                        <div class="card-body">
                            <h5 class="card-title">Documentos</h5>
                            <p class="card-text fs-2"> {{ dataCounts[1] }} </p>
                            <router-link 
                                class="btn btn-sm btn-primary"
                                title="Novo documento"
                                to="/document-upload">
                                <i class="fas fa-plus"></i> Novo
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-auto col-lg-4 col-md-6 col-sm-6">
                    <div class="card mt-3 mb-4">
                        <div class="card-body">
                            <h5 class="card-title">Perguntas</h5>
                            <p class="card-text fs-2"> {{ dataCounts[3] }} </p>
                            <router-link 
                                class="btn btn-sm btn-primary"
                                title="Nova pergunta"
                                to="/question-manager">
                                <i class="fas fa-plus"></i> Nova
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-auto col-lg-4 col-md-6 col-sm-6">
                    <div class="card mt-3 mb-4">
                        <div class="card-body">
                            <h5 class="card-title">Questionários</h5>
                            <p class="card-text fs-2"> {{ dataCounts[2] }} </p>
                            <router-link 
                                class="btn btn-sm btn-primary"
                                title="Novo questionário"
                                to="/quiz-new">
                                <i class="fas fa-plus"></i> Novo
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import SideBar from '@/components/common/side-bar';
    import Breadcrumb from '@/components/common/breadcrumb';
    import api from "@/services/api";

    export default {
        name: "DashboardIndex",
        data() {
            return {
                crumbsData: [
                    { crumb: 'Dashboard', link: { to: 'Dashboard' } },
                    { crumb: 'Overview', link: { to: 'Dashboard' } },
                ],
                sidebarData: "DashboardOverview",
                dataCounts: [],
            }
        },
        components: {
            SideBar,
            Breadcrumb,
        },
        watch: {},
        methods: {
            getCounts: function () {
                let self = this;
                api.get('/Dashboard/findAllCounters')
                    .then(function (response) { // Handle success
                        self.dataCounts = response.data;
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
        },
        computed: {},
        created() {
            this.getCounts();
        },
        mounted() {},
        unmounted() {},
    }
</script>

<style scoped>
</style>
