<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <a>
            <side-bar :menuActive="sidebarData" :theme="showLogoDarkMode" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <router-link class="d-flex align-items-center link-dark text-decoration-none" :to="{ name: 'DocumentList' }">
                        <span class="fs-5 logo">
                            <img src="./../../assets/img/logo-login.png" :title="$t('labelGoHome')"  v-if="!showLogoDarkMode" />
                            <img src="./../../assets/img/logo-home.png"  :title="$t('labelGoHome')"  style="padding: 10px 6px;" v-else />
                        </span>
                    </router-link>
                </li>
            </ul>
            <div class="dropdown-menu-user">
                <div class="dropdown" style="float:right">
                    <a class="d-flex align-items-center text-black text-decoration-none dropdown-toggle username" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg width="32" height="32" class="rounded-circle me-2 bd-circle" xmlns="http://www.w3.org/2000/svg" role="img" preserveAspectRatio="xMidYMid slice" focusable="false" v-if="profileImage === ''"></svg>
                        <img :src="profileImage" alt="Imagem do perfil" width="32" height="32" class="rounded-circle me-2" v-else>
                        {{ setBreakWord(user) }}
                    </a>
                    <a class="d-flex align-items-center text-black text-decoration-none dropdown-toggle username-collapsed" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg width="32" height="32" class="rounded-circle me-2 bd-circle" xmlns="http://www.w3.org/2000/svg" role="img" preserveAspectRatio="xMidYMid slice" focusable="false" v-if="profileImage === ''"></svg>
                        <img :src="profileImage" alt="Imagem do perfil" width="32" height="32" class="rounded-circle me-2" v-else>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-sidebar text-small shadow" aria-labelledby="dropdownUser1">
                        <li class="remove-hover">
                            <a class="dropdown-item">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckThemeOffSidebar" style="cursor: pointer;" v-on:change="toggleTheme">
                                    <label class="form-check-label" for="flexSwitchCheckThemeOffSidebar" style="cursor: pointer;">
                                        {{ $t('labelDarkTheme') }}
                                    </label>
                                </div>
                            </a>
                        </li>
                        <li> <hr class="dropdown-divider"> </li>
                        <li class="remove-hover">
                            <div class="dropdown-item">
                                <div class="btn-group">
                                    <a :class="$i18n.locale === 'pt' ? 'btn btn-light lang-link lang-active btn-lang' : 'btn btn-light lang-link btn-lang'"
                                        @click="setLanguage('pt')">
                                        <img src="./../../assets/img/lang-pt.png" alt="PT" />
                                        <span style="margin-left: 2px;">PT</span>
                                    </a>
                                    <a :class="$i18n.locale === 'en' ? 'btn btn-light lang-link lang-active btn-lang' : 'btn btn-light lang-link btn-lang'"
                                       @click="setLanguage('en')">
                                        <img src="./../../assets/img/lang-en.png" alt="EN" />
                                        <span style="margin-left: 2px;">EN</span>
                                    </a>
                                    <a :class="$i18n.locale === 'es' ? 'btn btn-light lang-link lang-active btn-lang' : 'btn btn-light lang-link btn-lang'"
                                       @click="setLanguage('es')">
                                        <img src="./../../assets/img/lang-es.png" alt="ES" />
                                        <span style="margin-left: 2px;">ES</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li> <hr class="dropdown-divider"> </li>
                        <li class="remove-hover">
                            <router-link class="dropdown-item" :to="{ name: 'Logout', query: { darkMode: this.showLogoDarkMode }}" title="Sair"> 
                                &nbsp;<i class="fas fa-sign-out-alt"></i> {{ $t('labelSignOut') }} 
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
    import SideBar from '@/components/common/side-bar';
    import axios from 'axios';

    export default {
        name: "NavBar",
        props: {
            sidebarData: {
                required: true,
                type: String,
                default: "",
            },
        },
        data() {
            return {
                title: "Component NavBar",
                showLogoDarkMode: false,
                profileImage: '',
                user: this.$store.state.userProfile.name,
            }
        },
        components: {
            SideBar
        },
        watch: {},
        methods: {
            setLanguage: function (lang) {
                this.$i18n.locale = lang;
                this.$store.commit('updateUserProfileLanguage', { amount: lang });
            },
            getProfileImage: function () {
                let self = this;
                axios.get("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
                    headers: {
                        Authorization: `Bearer ${self.$store.state.userProfile.tokenAzure}`
                    },
                    responseType: 'blob',
                }).then(function (response) {
                    self.profileImage = window.URL.createObjectURL(new Blob([response.data], { type: "image/jpeg" }));
                    self.$store.commit('updateUserProfileImage', { amount: self.profileImage });
                }).catch(error => {
                    console.log(error);
                });
            },
            setBreakWord: function (str) {
                var strSplit = str.split(" ");
                return strSplit[0] + " " + strSplit[strSplit.length - 1];
            },
            toggleTheme: function () {
                if (localStorage.getItem('theme') === 'css-theme-dark') {
                    this.setTheme('css-theme-light');
                    this.showLogoDarkMode = false;
                } else {
                    this.setTheme('css-theme-dark');
                    this.showLogoDarkMode = true;
                }
            },
            setTheme: function (themeName) {
                localStorage.setItem('theme', themeName);
                document.documentElement.className = themeName;
            },
        },
        computed: {},
        created() {
           this.getProfileImage();
        },
        mounted() {
            let self = this;
            (function () {
                if (localStorage.getItem('theme') === 'css-theme-dark') {
                    self.setTheme('css-theme-dark');
                    self.showLogoDarkMode = true;
                    document.getElementById('flexSwitchCheckThemeOffSidebar').checked = true;
                } else {
                    self.setTheme('css-theme-light');
                    self.showLogoDarkMode = false;
                    document.getElementById('flexSwitchCheckThemeOffSidebar').checked = false;
                }
            })();
        },
        unmounted() { },
    }
</script>
<style scoped>
    .navbar {
        padding: 0;
    }

    .navbar-light {
        background-color: #ffffff;
    }

    .navbar-toggler,
    .navbar-toggler-icon {
        display: none;
    }

    .navbar-expand-lg {
        flex-wrap: nowrap !important;
        justify-content: flex-start !important;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto !important;
    }

    .collapse {
        justify-content: space-between;
    }

    .dropdown-menu {
        width: 100%;
    }

    .dropdown-menu-user {
        padding: 0px 10px;
    }

    .dropdown-toggle {
        outline: 0;
    }

    .dropdown-item {
        padding: 0rem .2rem !important;
    }

    .remove-hover .dropdown-item, .remove-hover a:hover {
        color: #212529;
        background-color: #ffffff;
    }

    .form-switch {
        padding-left: 2.7em !important;
    }

    .bd-circle {
        border: 1px solid #C7C8C9 !important;
    }

    .btn.btn-light.lang-link,
    .btn.btn-light.lang-link:hover,
    .btn.btn-light.lang-link.lang-active,
    .btn.btn-light.lang-link.lang-active:hover {
        padding: 0.1rem 0.3rem;
        background-color: transparent;
        border: none;
    }

    .lang-link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lang-link.lang-active,
    .lang-link:hover {
        color: #0d6efd !important;
    }

    .btn-lang {
        padding: 2%;
        background-color: white;
        border-color: white;
    }

    .text-black {
        color: black;
    }

    @media (max-width: 309px) {
        .logo {
            width: 31px;
            height: 40px;
            overflow: hidden;
        }
    }

    @media (max-width: 768px) {
        .username-collapsed {
            display: block !important;
        }

        .username {
            display: none !important;
        }

        .dropdown-menu {
            right: 0 !important;
            left: auto !important;
        }
    }

    @media (min-width: 769px) {
        .username-collapsed {
            display: none !important;
        }

        .username {
            display: block !important;
        }
    }
</style>
