<template>
    <div class="col-md-6">
        <div class="mb-2" style="margin-top: 12px !important;">
            <div v-if="isPdf">
                <strong class="form-label mb-1">PDF ORIGINAL&nbsp;&nbsp;</strong>
                <div class="spinner-border spinner-border-sm text-primary" style="margin-right: 1%;" role="status" v-if="loading"></div>
                <span class="text-primary" v-if="loading"> {{ $t('labelLoadingFilePleaseWait') }}.. </span>
                <a  @click="openTab" v-if="srcPdf"> <i class="fas fa-expand text-primary" :title="$t('labelExpand')"></i> </a>
                <img src="../../../assets/img/go-to-text.png" @click="getInquiryNormalized" style="cursor: pointer; float: right;" :title="$t('labelDocumentTranscript')" v-if="srcPdf" />
                <div class="view-pdf" v-if="srcPdf">
                    <object :data="srcPdf +`#zoom=80`" type="application/pdf" width="100%" height="100%">
                        <embed :src="srcPdf" type="application/pdf" />
                    </object>
                </div>
                <div class="mt-1 p-2" v-if="errorPdf" style="border: 1px solid #dc3545; text-align: center; cursor: pointer;" @click="reloadPage">
                    <span class="text-danger" style="text-decoration: none;">
                        <i class="fas fa-exclamation-circle"></i> {{ $t('labelAttentionPDFDisplayFailed') }}.
                    </span>
                </div>
            </div>
            <div v-else>
                <div>
                    <strong class="form-label mb-3"> {{ upperFormat($t('labelStandardizedFullText')) }}&nbsp;&nbsp;</strong>
                    <i class="fas fa-spinner fa-pulse text-primary" v-if="loadingInquiryNormalized"></i>
                    <img src="../../../assets/img/go-to-pdf.png" @click="isPdf=true" style="cursor: pointer; float: right;" :title="$t('labelPdfBack')" />
                </div>
                <textarea type="text" class="form-control custom-textarea textarea-norm-full" v-model="contentInquiryNormalized" readonly></textarea>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "DocView",
        data() {
            return {
                idAnalyzer: this.$route.params.id,
                isPdf: true,
                srcPdf: null,
                errorPdf: false,
                loading: true,
                loadingInquiryNormalized: false,
                contentInquiryNormalized: "",
                controllAttempt: 0,
            }
        },
        components: {},
        watch: {},
        methods: {
            getDataInquiry: function () {
                let self = this;
                api.get('/Inquiry/Analyze/' + this.idAnalyzer)
                    .then(function (result) { // Handle success
                        self.getPdf(result.data.file.name, 2);
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            getPdf: function (nrIDEA, attempt) {
                this.controllAttempt++;
                this.srcPdf = null;
                this.errorPdf = false;
                let self = this;
                let baseURL = ENV_CONFIG.VUE_APP_BASE_URL_API_AZURE;
                api.get(baseURL + '/api/FileRetrieverAsync?fileGuidId=' + nrIDEA,
                    {
                        responseType: 'blob',
                        headers: {
                            'x-functions-key': ENV_CONFIG.VUE_APP_KEY_API_AZURE,
                        }
                    })
                    .then(function (response) { // Handle success
                        self.srcPdf = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                        console.log(self.srcPdf);
                        self.loading = false;
                    }).catch(function (e) { // Handle error
                        console.log(e);
                        if (self.controllAttempt < attempt) {
                            self.getPdf(nrIDEA, attempt);
                        } else {
                            self.loading = false;
                            self.errorPdf = true;
                        }
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            getInquiryNormalized: function () {
                this.loadingInquiryNormalized = false;
                this.isPdf = false;
                let self = this;
                if (this.contentInquiryNormalized == "") {
                    this.loadingInquiryNormalized = true;
                    api.get('/Inquiry/Normalized/' + this.idAnalyzer)
                        .then(function (result) { // Handle success
                            self.contentInquiryNormalized = result.data.content;
                            self.loadingInquiryNormalized = false;
                        }).catch(function (e) { // Handle error
                            console.log(e);
                            self.loadingInquiryNormalized = false;
                        }).finally(function () { // Always executed
                            console.log("Finished request.");
                        });
                }
            },
            openTab: function () {
                window.open(this.srcPdf, '_blank');
            },
            upperFormat: function (str) {
                return str.toUpperCase();
            },
            reloadPage: function () {
                location.reload();
            },
        },
        computed: {},
        created() {
            this.getDataInquiry();
        },
        mounted() {},
        unmounted() {},
    }
</script>

<style scoped>
    .fas, .far {
        font-weight: 900 !important;
    }

    .text-primary {
        color: #47AAFF !important;
    }

    .custom-textarea {
        border-color: #0073E6 !important;
    }

    .view-pdf {
        width: 100% !important;
        height: calc(100vh - 138px) !important;
    }

    .textarea-norm-full {
        height: calc(100vh - 138px) !important;
    }
</style>
