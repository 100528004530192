<template>
    <main class="flex-shrink-0" v-if="loading">
        <div class="container mb-5">
            <div class="row justify-content-md-center" style="height: 100%;">
                <div class="col-md-auto">
                    <div class="div-center">
                        <div>
                            <div class="mb-3" style="width: 100%;float: left;"> <h5 class="h5-custom-modal"> {{ message }} </h5> </div>
                            <div style="text-align: center;">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" v-bind:svg-inline="''" v-bind:width="'60'" v-bind:class="'refresh-animated'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="25" cy="25" r="21.2" fill="none" stroke="#0073e6" stroke-width="2" stroke-miterlimit="10"/><circle cx="25" cy="4.6" r="4.5" fill="#4282b3"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <main v-if="!loading">
        <div class="container-fluid">
            <div class="mb-2 navbar-container">
                <div class="row">
                    <nav-bar :sidebarData="sidebarData" />
                </div>
            </div>
            <div class="row">
                <breadcrumb :crumbs="crumbsData" />
            </div>
            <div class="row" style="max-height: calc(100% - 70px); overflow-y: auto;">
                <div class="col-md-12">
                    <div class="form-upload">
                        <form @submit="save">
                            <h5 class="mb-4">{{ $t('labelGeneralInformation') }}</h5>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                                <label class="form-label" for="inputFileId">{{ $t('labelUploadPdf') }}</label>
                                <div class="input-group custom-file-button" @click="clickUplodFile"> 
                                    <i class="input-group-text fas fa-file-upload"></i>
                                    <input type="text" class="form-control border-right" ref="fileInpt" :value="form.files.length != 0 ? form.files[0].file.name : $t('labelNoFileChosen')" readonly />
                                    <input type="file" class="form-control" id="inputFileId" 
                                    style="display: none;" 
                                    name="Files" ref="file" 
                                    @change="handleFileUpload" 
                                    v-validate
                                    accept="application/pdf">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="ideaId">{{ $t('labelName') }}</label>
                                <input type="text" class="form-control" id="ideaId" 
                                 v-validate
                                 autocomplete="off" v-model="form.IDEA" required>
                            </div>
                            <div class="mb-1">
                                <label class="form-label" for="descId">{{ $t('labelDescriptionDocumentNote') }}</label>
                                <textarea class="form-control" rows="5" id="descId" 
                                v-validate
                                name="text" maxlength="250" 
                                v-model="form.description" required></textarea>
                            </div>
                            <div class="mb-0">
                                <a style="text-decoration: none; color: #AEB2BA; cursor: default;">{{ 250 - form.description.length }} {{ (250 - form.description.length) > 1 ? $t('labelCharacters') : $t('labelCharacter') }}</a>
                            </div>
                            <button type="submit" class="btn btn-primary m-2" :title="$t('labelSend')" style="float:right">{{ $t('labelSend') }}</button>
                            <router-link class="btn btn-secondary m-2 btn-custom-cancel" style="float:right" :to="{ name: 'DocumentList', query: { page: '1' } }" :title="$t('labelCancel')">{{ $t('labelCancel') }}</router-link>
                        </form>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
        </div>
        <!-- Component ToastAlert -->
        <toast-alert :showToast="toastShow" :colorToast="toastColor" :messageToast="toastMessage" @close="closeToast" />
    </main>
    <!-- Component ModalAlert -->
    <modal-alert v-if="modalAlertShow" :type="'Error'" :alertTitle="$t('labelUploadFailure')" :alertMessage="$t('labelTheFileMayBeCorrupt')" :okLabel="$t('labelClose')" @close="closeModal" />
</template>

<script>
    import NavBar from '@/components/common/nav-bar';
    import Breadcrumb from '@/components/common/breadcrumb';
    import ModalAlert from '@/components/common/modal-alert';
    import ToastAlert from '@/components/common/toast-alert';
    import api from "@/services/api";

    export default {
        name: "DocumentUpload",
        directives: {
            validate: {
                inserted: function (el, binding) {
                    el.addEventListener('input', function () {
                        el.setCustomValidity('');
                        if (!el.checkValidity()) {
                            el.reportValidity();
                        }
                    });

                    el.addEventListener('invalid', function (event) {
                        event.preventDefault();
                        if (el.validity.valueMissing) {
                            el.setCustomValidity(this.$t('labelFillInThisField'));
                        }
                        el.reportValidity();
                    });
                }
            }
        },
        data() {
            return {
                crumbsData: [],
                sidebarData: "DocumentUpload",
                title: "Form Here",
                form: {
                    files: [],
                    IDEA: '',
                    description: '',
                    emailCreator: '',
                },
                loading: false,
                message: "",
                modalAlertShow: false,
                myInterval: null,
                fileUpload: null,
                chunks: [],
                toastShow: false,
                toastColor: "",
                toastMessage: "",
                timeoutMessage: ENV_CONFIG.VUE_APP_WAITING_TIME_MSG_UPLD,
                timerReq: ENV_CONFIG.VUE_APP_TIMER_REQ,
            }
        },

        components: {
            NavBar,
            Breadcrumb,
            ModalAlert,
            ToastAlert,
        },
        watch: {
            chunks: {
                handler(val, oldVal) {
                    if (val.length > 0) {
                        this.uploadChunks();
                    }
                },
                deep: true
            },
            '$store.state.userProfile.language': function() {
                this.setCrumbsData();
            },
        },
        methods: {
            clickUplodFile: function() {
                document.getElementById("inputFileId").click();
            },
            setCrumbsData: function () {
                this.crumbsData = [
                    { crumb: this.$t('labelDocuments'), link: { to: 'DocumentList' } },
                    { crumb: this.$t('labelUpload'), link: { to: 'DocumentUpload' } },
                ];
            },
            checkExceededPages: function () {
                let self = this;
                api.get('/Inquiry/CheckExceededPages')
                    .then(function (response) { // Handle success
                        if (response.data === true) {
                            self.clearMyInterval();
                            self.alertToast(self.$t('labelNumberOfPagesHasBeenExceeded'), "toast-warning");
                        }
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            handleFileUpload: function () {
                this.form.files = [];
                var error = false;
                for (var j = 0; j < this.$refs.file.files.length; j++) {
                    // File extension validation
                    if (/\.(pdf)$/i.test(this.$refs.file.files[j].name)) {
                        this.form.files.push({
                            file: this.$refs.file.files[j]
                        });
                    } else {
                        error = true;
                    }
                    if (j === this.$refs.file.files.length - 1) { // Trigger at the end of the loop
                        if (error) {
                            console.log("O arquivo não é .pdf");
                            this.$refs.file.value = null;
                        }
                    }
                }
            },
            save: function (e) {
                e.preventDefault();
                if (this.form.files.length == 0) {
                    this.$refs.fileInpt.focus();
                    this.clearMyInterval();
                    this.alertToast(this.$t('labelNoFileChosen') + ".", "toast-warning");
                } else {
                    window.onbeforeunload = function () { return true; };
                    this.message = this.$t('labelSendingTheDocument');
                    this.loading = true;
                    this.setTimeMessage();
                    this.fileUpload = this.form.files[0].file;
                    this.createChunks(this.form.files[0].file);
                }
            },
            createChunks: function (file) {
                let size = 19922944, chunks = Math.ceil(file.size / size); // Size of chunks = 19MB
                for (let i = 0; i < chunks; i++) {
                    this.chunks.push(file.slice(
                        i * size, Math.min(i * size + size, file.size), file.type
                    ));
                }
            },
            uploadChunks: function () {
                let self = this;
                let timer = this.timerReq; // time in minutes
                console.log("TMT" + timer);
                api.post('/Inquiry/UploadByChunks', this.reqFormData,
                    {
                        headers: { 'Content-Type': 'application/octet-stream' },
                        timeout: ((60 * 1000) * timer),
                        onUploadProgress: (progressEvent) => {
                            let progress = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            console.log(`O Arquivo ${self.fileUpload.name} está ${progress}% carregado... `);
                        },
                    })
                    .then(function (response) { // Handle success
                        self.chunks.shift();
                        if (self.chunks.length === 0) { // Last chunk sent
                            if (response.status === 200) { // Status 200 = Finished
                                window.onbeforeunload = null;
                                clearInterval(self.myInterval);
                                self.redirectToList();
                            }
                        }
                    }).catch(function (e) { // Handle error
                        window.onbeforeunload = null;
                        console.log(e);
                        self.loading = false;
                        clearInterval(self.myInterval);
                        self.showModal();
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            saveOld: function (e) {
                e.preventDefault();
                window.onbeforeunload = function () { return true; };
                this.message = this.$t('labelSendingTheDocument');
                this.loading = true;
                this.setTimeMessage();
                var formData = new FormData();
                formData.append("file", this.form.files[0].file);
                formData.append("nrIDEA", this.form.IDEA);
                formData.append("description", this.form.description);
                formData.append("emailCreator", this.$store.state.userProfile.login);
                let self = this;
                api.post('/Inquiry', formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(function (response) { // Handle success
                        window.onbeforeunload = null;
                        clearInterval(self.myInterval);
                        self.redirectToList();
                    }).catch(function (e) { // Handle error
                        window.onbeforeunload = null;
                        console.log(e);
                        self.loading = false;
                        clearInterval(self.myInterval);
                        self.showModal();
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            showModal: function () {
                this.modalAlertShow = true;
                document.getElementsByTagName("BODY")[0].children[1].className += " active";
            },
            closeModal: function () {
                this.modalAlertShow = false;
                document.getElementsByTagName("BODY")[0].children[1].className = "overlay";
            },
            redirectToList: function () {
                let self = this;
                setTimeout(function () {
                    self.$router.push({ name: 'DocumentList', query: { page: '1' } });
                }, 500);
            },
            alertToast: function (msg, color) {
                this.toastMessage = msg;
                this.toastColor = color;
                this.toastShow = true;
                let self = this;
                this.myInterval = setInterval(function () {
                    self.toastMessage = "";
                    self.toastColor = "";
                    self.toastShow = false;
                    clearInterval(self.myInterval);
                }, 4000);
            },
            closeToast: function () {
                this.toastShow = false;
                this.clearMyInterval();
            },
            clearMyInterval: function () {
                clearInterval(this.myInterval);
                this.myInterval = null;
            },
            setTimeMessage: function () {
                let self = this;
                this.myInterval = setInterval(function () {
                    self.message = self.$t('labelAttentionDependingOnTheSpeed');
                    clearInterval(self.myInterval);
                }, this.timeoutMessage);
            },
            cleanForm: function () {
                this.form = {
                    files: [],
                    IDEA: '',
                    description: '',
                    emailCreator: ''
                };
                this.$refs.file.value = null;
            },
        },
        computed: {
            reqFormData() {
                let formData = new FormData;
                formData.set('chunk', this.chunks[0], `${this.fileUpload.name}.part`);
                formData.set('filename', this.fileUpload.name);
                formData.set('isLast', this.chunks.length === 1);
                formData.set('nrIDEA', this.form.IDEA);
                formData.set('description', this.form.description);
                formData.set('emailCreator', this.$store.state.userProfile.login);
                return formData;
            },
        },
        created() {
            this.setCrumbsData();
            //this.checkExceededPages();
        },
        mounted() { },
        unmounted() { },
    }
</script>

<style scoped>
    .input-group-text {
        padding: 0.6rem 0.75rem !important;
    }

    h3 {
        color: black;
        margin-top: 2%;
        text-align: left;
    }

    .form-upload {
        padding-top: 20px !important;
    }

    .custom-file-button input[type=text], 
    .custom-file-button input[type=file] {
        margin-left: -2px !important;
    }

    .custom-file-button input[type=file]::-webkit-file-upload-button {
        display: none;
    }

    .custom-file-button input[type=file]::file-selector-button {
        display: none;
    }

    .custom-file-button:hover label {
        cursor: pointer;
    }

    .fas {
        font-weight: 900 !important;
    }

    .btn-custom-cancel {
        font-weight: inherit !important;
        padding: 8px 12px !important;
        border: 0 !important;
    }

    .div-center {
        position: relative;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        /*width: 500px;*/
    }

    .h5-custom-modal {
        font-weight: initial;
        color: #0073E6;
        text-align: center;
    }

    .border-right {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }

    /* Refresh animated  */
    .refresh-animated {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .container-fluid {
        padding: 0 13px;
    }

    #descId{
        height: 100px;
    }
</style>
