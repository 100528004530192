<template>
    <div class="div-row background-white shadow">
        <div class="row" style="margin-top: 35px !important;">
            <div class="col mt-2">
                <h6 class="mt-0 text-primary">
                    <i class="fas fa-compress" :title="$t('labelReduceHistory')" @click="expandHistory" v-show="isExpandedHistory" style="margin-left: 1%; cursor: pointer;"></i>
                    <i class="fas fa-expand text-primary" :title="$t('labelExpandHistory')" @click="expandHistory" v-show="!isExpandedHistory" style="margin-left: 4%; cursor: pointer;"></i>
                    {{ $t('labelHistoric') }}
                    <a  style="margin-left: 1%;" @click="expandSort">
                        <i class="fas fa-sort" style="color: #0073E6;" :title="$t('labelOrder')"></i>
                    </a>
                    <span class="badge rounded-pill bg-primary badge-custom" style="margin-left: 1.5%;cursor: pointer;" :title="$t('labelDownloadCsv')" @click="downloadHistory" v-if="historyList.length > 0">
                        <i class="fas fa-cloud-download-alt"></i> {{ historyList.length }}
                    </span>
                    <span class="badge rounded-pill bg-danger badge-custom" style="margin-left: 1.5%;cursor: pointer;" :title="$t('labelDeleteHistory')" @click="confirmationDialog(idAnalyzer)" v-if="historyList.length > 0">
                        <i class="fas fa-trash" style="font-size: .9em;"></i>
                    </span>
                    <span class="dropdown-menu" v-show="isExpandedSort" style="display: block">
                        <a class="dropdown-item"  @click="sortHistoryList">
                            <i class="fas fa-check" style="color: #0073E6;" title="check" v-show="historyListOrder == 'desc'"></i>
                            <i class="far fa-circle" style="color: #0073E6;" title="circle" v-show="historyListOrder == 'asc'"></i>
                            &nbsp;{{ $t('labelMostRecent') }}
                        </a>
                        <a class="dropdown-item"  @click="sortHistoryList">
                            <i class="fas fa-check" style="color: #0073E6;" title="check" v-show="historyListOrder == 'asc'"></i>
                            <i class="far fa-circle" style="color: #0073E6;" title="circle" v-show="historyListOrder == 'desc'"></i>
                            &nbsp;{{ $t('labelMostOlder') }}
                        </a>
                    </span>
                </h6>
            </div>
        </div>
        <div :class="historyList.length == 0 ? 'row mb-2' : 'row'" v-if="loadingHistory">
            <span>
                &nbsp;&nbsp;&nbsp;<i class="fas fa-sync-alt fa-spin"></i>&nbsp;{{ $t('labelLoading') }}..
            </span>
        </div>
        <div class="row mb-2" v-if="!loadingHistory && historyList.length == 0">
            <span>
                &nbsp;&nbsp;&nbsp;{{ $t('labelQueryWithoutHistory') }}.
            </span>
        </div>
        <div class="div-row-overflow">
            <div class="row" v-for="history in historyList">
                <div class="col-md-12">
                    <div class="div-card">
                        <div class="card shadow">
                            <div class="card-body">
                                <h5 class="card-title" style="margin-bottom: 0;"> {{history.input}} </h5>
                                <div class="card-subtitle mb-1 text-primary">
                                    <div style="float: right;">
                                        <a  @click="copyToClipboard(history.output)"> <i class="fas fa-clone text-primary" :title="$t('labelCopy')"></i> </a>
                                    </div>
                                </div>
                                <textarea class="form-control mb-1 custom-textarea" rows="5" v-model="history.output" readonly></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Component ModalAlert -->
    <modal-alert v-if="modalAlertShow" :type="'Confirm'" :entity="modalEntity" :alertTitle="$t('labelYouAreAboutToDeleteDocumentQuery')" :alertMessage="$t('labelThisActionCannotBeUndone')" :okLabel="$t('labelConfirm')" :cancelLabel="$t('labelCancel')" @open="deleteHistory" @close="closeModal" />
</template>

<script>
    import * as moment from "moment/moment";
    import api from "@/services/api";
    import ModalAlert from '@/components/common/modal-alert';

    export default {
        name: "HistoryView",
        emits: ["expandHistory", "showAlertToast", "clearMyInterval", "updateHistoryListOrder"],
        props: {
            dataShowHistory: {
                required: true,
                type: Boolean,
                default: true
            },
            dataIsExpandedHistory: {
                required: true,
                type: Boolean,
                default: false
            },
            dataUnshiftHistoryList: {
                required: true,
                type: Object,
                default: {}
            },
            dataPushHistoryList: {
                required: true,
                type: Object,
                default: {}
            },
        },
        data() {
            return {
                idAnalyzer: this.$route.params.id,
                historyList: [],
                loadingHistory: false,
                isExpandedHistory: this.dataIsExpandedHistory,
                isExpandedSort: false,
                historyListOrder: "desc",
                modalAlertShow: false,
                modalEntity: {},
            }
        },
        components: {
            ModalAlert,
        },
        watch: {
            dataShowHistory: {
                handler(val, oldVal) {
                    this.showHistory();
                },
                deep: true
            },
            dataIsExpandedHistory: {
                handler(val, oldVal) {
                    this.isExpandedHistory = val;
                },
                deep: true
            },
            dataUnshiftHistoryList: {
                handler(val, oldVal) {
                    this.unshiftHistoryList(val);
                },
                deep: true
            },
            dataPushHistoryList: {
                handler(val, oldVal) {
                    this.pushHistoryList(val);
                },
                deep: true
            },
        },
        methods: {
            showHistory: function () {
                this.loadingHistory = true;
                let self = this;
                api.get('/Inquiry/History/' + this.idAnalyzer)
                    .then(function (response) { // Handle success
                        setTimeout(function () {
                            self.loadingHistory = false;
                            self.historyList = response.data.value.reverse();
                        }, 1000);
                    }).catch(function (e) { // Handle error
                        console.log(e);
                        self.historyList = [];
                        self.loadingHistory = false;
                        self.clearMyInterval();
                        self.alertToast(self.$t('labelFailedToLoadHistory'), "toast-danger");
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
                this.historyListOrder = "desc";
            },
            downloadHistory: function () {
                var csv = "Input;Output";
                for (var i = 0; i < this.historyList.length; i++) {
                    csv += "\n" + this.historyList[i].input + ";" + (this.historyList[i].output).replaceAll(/[\r\n\s]+/g, ' ');
                    if (i == this.historyList.length - 1) {
                        // Download forced - Begin
                        var fileURL = window.URL.createObjectURL(new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8" }));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', this.$t('labelHistoric') + ' - ' + this.dateFormat(Date.now()) + '.csv');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        // Download forced - End
                        this.clearMyInterval();
                        this.alertToast(this.$t('labelDownloadSuccessfully'), "toast-success");
                    }
                }
            },
            confirmationDialog: function (item) {
                this.modalEntity = { id: item };
                this.modalAlertShow = true;
                document.getElementsByTagName("BODY")[0].children[1].className += " active";
            },
            deleteHistory: function (id) {
                let self = this;
                api.delete('/Inquiry/History/' + id)
                    .then(function (response) { // Handle success
                        self.closeModal();
                        setTimeout(() => self.showHistory(), 100);
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            closeModal: function () {
                this.modalAlertShow = false;
                document.getElementsByTagName("BODY")[0].children[1].className = "overlay";
            },
            expandHistory: function () {
                this.isExpandedHistory = !this.isExpandedHistory;
                this.$emit('expandHistory');
            },
            unshiftHistoryList: function (data) {
                this.historyList.unshift({
                    input: data.input,
                    output: data.output
                });
                $(".div-row-overflow").animate(
                    { scrollTop: 0 }, 500
                );
            },
            pushHistoryList: function (data) {
                this.historyList.push({
                    input: data.input,
                    output: data.output
                });
                let self = this;
                setTimeout(function () { self.moveSidebar(); }, 100);
            },
            moveSidebar: function () {
                $(".div-row-overflow").animate(
                    { scrollTop: $('.div-row-overflow')[0].scrollHeight }, 500
                );
            },
            copyToClipboard: function (content) {
                navigator.clipboard.writeText(content);
                this.clearMyInterval();
                this.alertToast(this.$t('labelTextCopiedToClipboard'), "toast-primary");
            },
            alertToast: function (msg, color) {
                this.$emit('showAlertToast', { msg: msg, color: color });
            },
            clearMyInterval: function () {
                this.$emit('clearMyInterval');
            },
            expandSort: function () {
                this.isExpandedSort = !this.isExpandedSort;
            },
            sortHistoryList: function () {
                this.isExpandedSort = false;
                if (this.historyListOrder == "desc") {
                    this.historyListOrder = "asc";
                    this.$emit('updateHistoryListOrder', { value: "asc" });
                    this.historyList.reverse();
                } else if (this.historyListOrder == "asc") {
                    this.historyListOrder = "desc";
                    this.$emit('updateHistoryListOrder', { value: "desc" });
                    this.historyList.reverse();
                }
            },
            dateFormat: function (str) {
                if (this.$store.state.userProfile.language === "en") {
                    return moment(str).format("YYYY/MM/DD (H:mm:ss)");
                } else {
                    return moment(str).format("DD/MM/YYYY (H:mm:ss)");
                }
            },
        },
        computed: {},
        created() {
            this.showHistory();
        },
        mounted() {},
        unmounted() {},
    }
</script>

<style scoped>
    .fas, .far {
        font-weight: 900 !important;
    }

    .text-primary {
        color: #47AAFF !important;
    }

    .div-row {
        border-radius: 6px !important;
    }

    .div-row-overflow {
        max-height: calc(100vh - 175px) !important;
        overflow: hidden;
        overflow-y: auto !important;
        overflow-x: none !important;
    }

    .div-card {
        padding: 10px;
    }

    .card-body {
        padding: 0.5rem 0.8rem !important;
    }

    .card-title {
        font-weight: normal !important;
        font-size: 1em !important;
    }

    .card-subtitle {
        font-weight: normal !important;
        font-size: 0.7em !important;
    }

    .custom-textarea {
        border-color: #0073E6 !important;
    }
</style>
