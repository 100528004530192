<template>
    <div class="col-md-3">
        <div class="mb-1 mt-2">
            <img src="./../../../assets/img/prompt.png" />
        </div>
        <div class="mb-2 mt-1">
            <div class="div-quiz">
                <select class="form-select custom-input" id="selectText" v-model="quizSelected">
                    <option value=""> {{ $t('labelSelectQuestionnaire') }} </option>
                    <option :value="item" v-for="(item, index) in quizzes" :key="index"> {{ item.title }} </option>
                </select>
                <a  class="btn btn-outline-primary" style="margin: 0px 4.5px 0px 5px; min-width: max-content; box-shadow: none !important;" :title="$t('labelViewQuestions')" v-if="quizSelected.id && !loadingApplying" @click="openModal">
                    <span class="badge rounded-pill bg-primary badge-custom"> {{ quizSelected.questions.length }} </span> | <i class="fas fa-search-plus"></i>
                </a>
                <a  class="btn btn-primary" :title="$t('labelApplyQuestionnaire')" v-if="quizSelected.id && !loadingApplying" @click="sendQuiz">
                    <i class="far fa-arrow-circle-up"></i>
                </a>
                <a  class="btn btn-primary" style="margin: 0px 4.5px 0px 5px; min-width: max-content; box-shadow: none !important;" :title="$t('labelApplyingQuestionnaire')" v-if="quizSelected.id && loadingApplying">
                    <i class="fas fa-sync-alt fa-spin"></i>
                </a>
            </div>
        </div>
        <div class="mb-1 mt-1">
            <div class="input-group custom-input-button">
                <input type="text" class="form-control custom-input" :placeholder="$t('labelAskTheAi')" id="inputText" v-model="input" @keyup.enter="submitInput">
                <i class="input-group-text fas fa-arrow-circle-up custom-input" :title="$t('labelSendQuestion')" v-if="input.length > 0" @click="submitInput"></i>
            </div>
        </div>
        <div class="mb-2">
            <div style="width: 100%;">
                <div style="float: left;">
                    <strong class="form-label mb-3"> {{ upperFormat($t('labelOutput')) }} </strong>&nbsp;
                    <i class="fas fa-spinner fa-pulse text-primary" v-if="loadingOutput"></i>
                </div>
                <div style="float: right;" v-if="output != ''">
                    <a  @click="copyToClipboard(output)"> <i class="fas fa-clone text-primary" :title="$t('labelCopy')"></i> </a>
                </div>
            </div>
            <textarea type="text" class="form-control custom-textarea-disabled textarea-out-full" disabled v-if="output.length === 0"></textarea>
            <textarea type="text" class="form-control custom-textarea textarea-out-full" v-model="output" readonly v-else></textarea>
        </div>
        <div class="mb-2">
            <span v-if="hashInquiry != ''" title="Hash">
                <i class="fas fa-hashtag"></i> {{ hashInquiry }}
            </span>
        </div>
    </div>
    <!-- Component ModalQuestion -->
    <modal-question v-if="showModalQuestion" :dataQuiz="quizSelected" @close="closeModal" />
</template>

<script>
    import ModalQuestion from '@/components/pages/analyzer/modal-question';
    import api from "@/services/api";

    export default {
        name: "PromptView",
        emits: ["showHistory", "unshiftHistoryList", "pushHistoryList", "showAlertToast", "clearMyInterval"],
        props: {
            hashInquiry: {
                required: true,
                type: String,
                default: ''
            },
            historyListOrder: {
                required: true,
                type: String,
                default: ''
            },
        },
        data() {
            return {
                idAnalyzer: this.$route.params.id,
                input: '',
                output: '',
                quizzes: [],
                quizSelected: '',
                loadingApplying: false,
                loadingOutput: false,
                showModalQuestion: false,
            }
        },
        components: {
            ModalQuestion,
        },
        watch: {},
        methods: {
            getQuizzes: function () {
                let self = this;
                api.get('/Questionnaire')
                    .then(function (response) { // Handle success
                        self.quizzes = response.data;
                    }).catch(function (e) { // Handle error
                        console.log(e);
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            sendQuiz: function () {
                this.loadingApplying = true;
                this.clearMyInterval();
                this.alertToast(this.$t('labelApplyingQuestionnaireWait'), "toast-primary");
                let paramsReq = {
                    idDocument: parseInt(this.idAnalyzer),
                    idQuestionnaire: this.quizSelected.id,
                }
                let self = this;
                api.post('/Inquiry/inputQuestionnaire', paramsReq)
                    .then(function (response) { // Handle success
                        self.loadingApplying = false;
                        self.clearMyInterval();
                        self.alertToast(self.$t('labelQuestionnaireAppliedSuccessfully'), "toast-success");
                        setTimeout(() => self.$emit('showHistory'), 3000);
                    }).catch(function (e) { // Handle error
                        console.log(e);
                        self.loadingApplying = false;
                        self.clearMyInterval();
                        if (e.response && e.response.data === "No Credits to send a Question") {
                            self.alertToast(self.$t('labelNumberOfQuestionsHasBeenExceeded'), "toast-danger");
                        } else if (e.response.status === 404) {
                            self.alertToast(self.$t('labelAnInconsistencyWasIdentifiedInTheDocument'), "toast-danger");
                        } else if (e.response.status === 402) {
                            self.alertToast(self.$t('labelThereIsNotEnoughCredit'), "toast-warning");
                            setTimeout(() => self.$emit('showHistory'), 3000);
                        } else {
                            self.alertToast(self.$t('labelFailedToApplyQuestionnaire'), "toast-danger");
                        }
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            submitInput: function () {
                this.loadingOutput = true;
                this.output = "";
                let paramsReq = {
                    id: this.idAnalyzer,
                    input: this.input
                }
                this.input = "";
                let self = this;
                api.post('/Inquiry/input/', paramsReq)
                    .then(function (response) { // Handle success
                        if (typeof response.data === 'object') {
                            self.output = JSON.stringify(response.data, undefined, 2);
                        } else {
                            self.output = response.data;
                        }
                        if (self.historyListOrder == "desc") {
                            self.$emit('unshiftHistoryList', { input: paramsReq.input, output: self.output });
                        } else {
                            self.$emit('pushHistoryList', { input: paramsReq.input, output: self.output });
                        }
                        self.loadingOutput = false;
                    }).catch(function (e) { // Handle error
                        console.log(e);
                        self.loadingOutput = false;
                        if (e.response.data === "No Credits to send a Question") {
                            self.output = "";
                            self.clearMyInterval();
                            self.alertToast(self.$t('labelNumberOfQuestionsHasBeenExceeded'), "toast-danger");
                        } else if (e.response.status === 404) {
                            self.output = self.$t('labelAnInconsistencyWasIdentifiedInTheDocument');
                        } else {
                            self.output = self.$t('labelFailedNoResponse');
                        }
                    }).finally(function () { // Always executed
                        console.log("Finished request.");
                    });
            },
            openModal: function () {
                this.showModalQuestion = true;
                document.getElementsByTagName("BODY")[0].children[1].className += " active";
            },
            closeModal: function () {
                this.showModalQuestion = false;
                document.getElementsByTagName("BODY")[0].children[1].className = "overlay";
            },
            copyToClipboard: function (content) {
                navigator.clipboard.writeText(content);
                this.clearMyInterval();
                this.alertToast(this.$t('labelTextCopiedToClipboard'), "toast-primary");
            },
            alertToast: function (msg, color) {
                this.$emit('showAlertToast', { msg: msg, color: color });
            },
            clearMyInterval: function () {
                this.$emit('clearMyInterval');
            },
            upperFormat: function (str) {
                return str.toUpperCase();
            },
        },
        computed: {},
        created() {
            this.getQuizzes();
        },
        mounted() {},
        unmounted() {},
    }
</script>

<style scoped>
    .fas, .far {
        font-weight: 900 !important;
    }

    .text-primary {
        color: #47AAFF !important;
    }

    .div-quiz {
        display: flex;
    }

    .custom-input, .custom-textarea {
        border-color: #0073E6 !important;
    }

    .custom-input-button .input-group-text {
        padding: 0.6rem 0.75rem !important;
        background-color: var(--color-bg-form-control) !important;
        color: var(--color-bg-icon-active) !important;
        border-left: none !important;
        cursor: pointer !important;
    }

    .textarea-out-full {
        height: calc(100vh - 278px) !important;
    }
</style>
